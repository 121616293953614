import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about';

const AboutPage = () => {
    return (
        <Layout>
            <SEO title="À propos" />
            <About margin />
        </Layout>
    );
};

export default AboutPage;

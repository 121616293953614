import React from 'react';

export default ({ margin = false }) => {
    return (
        <section className={`py-12 bg-white ${margin ? 'pt-28' : ''}`}>
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
                <div className="lg:text-center">
                    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        À propos d'Ethibox
                    </h3>

                    <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                        Ethibox est un hébergeur web français créé en 2018 par un <a href="https://twitter.com/_johackim" target="_blank">Hacker Indépendant</a> avec <b>zéro financement</b>. Tout le code source est <a href="https://github.com/ethibox" target="_blank" className="font-bold">open-source</a>. Ethibox ne fait <b>aucune publicité</b> ou campagne marketing payante sur les réseaux sociaux, sur les moteurs de recherche et ailleurs. Ethibox est membre des <a href="https://chatons.org" target="_blank">CHATONS</a>.
                    </p>
                </div>

                <div className="mt-10 pb-12 bg-white sm:pb-16">
                    <div className="relative">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="max-w-4xl mx-auto">
                                <dl className="rounded-lg bg-white sm:grid sm:grid-cols-3">
                                    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                        <dd className="order-1 text-5xl font-extrabold text-gray-600">100%</dd>
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Des revenus proviennent des abonnements</dt>
                                    </div>
                                    <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                        <dd className="order-1 text-5xl font-extrabold text-gray-600">Zéro</dd>
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Financement extérieur par des ventures capitalistes</dt>
                                    </div>
                                    <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                        <dd className="order-1 text-5xl font-extrabold text-gray-600">+{new Date().getFullYear() - 2018} ans</dd>
                                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">D'existence</dt>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-center">Merci à <a href="https://framasoft.org" target="_blank">Framasoft</a>, aux <a href="https://chatons.org" target="_blank">CHATONS</a> 🐱 et à tous nos clients pour leur confiance ❤️</p>
            </div>
        </section>
    );
};
